import React from "react";
import { Link } from "gatsby";
import Footer from "../components/footer";
import Nav from "../components/nav";
import SEO from "../components/seo";

export default function Tos() {
  return (
    <>
      <SEO title="Ketentuan Layanan - Rupiah.trade" />
      <Nav />
      <div
        data-aos="fade-up"
        className="px-4 pt-8 pb-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8"
      >
        <h1 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-slate-900 sm:text-4xl md:mt-16">
          Ketentuan Layanan
        </h1>
        <div className="prose lg:prose-lg prose-slate max-w-full mt-8 ">
          <p>Selamat datang di Rupiah.trade!</p>
          <p>
            Syarat dan ketentuan ini menguraikan aturan penggunaan situs web
            Rupiah.trade, yang Anda akses melalui{" "}
            <a href="https://rupiah.trade">https://rupiah.trade</a>.
          </p>
          <p>
            Dengan menggunakan layanan situs web ini, kami menganggap Anda telah
            menerima syarat dan ketentuan ini. Jika Anda tidak setuju dengan
            semua syarat dan ketentuan yang tercantum pada halaman ini, harap
            segera tinggalkan situs web ini.
          </p>
          <p>
            Istilah berikut ini berlaku untuk Syarat dan Ketentuan, Pernyataan
            Privasi dan Pemberitahuan Penolakan dan semua Perjanjian pada situs
            web ini: “Pengguna”, “Pembeli”, “Pengunjung” dan “Anda” mengacu pada
            Anda, orang yang mengakses situs web ini dan mematuhi persyaratan
            dan ketentuanyang ada. “Kami”, mengacu pada pengelola situs web ini.
            “Pihak”, mengacu pada Anda dan diri kami sendiri. Semua istilah
            mengacu pada penawaran, penerimaan, dan pertimbangan pembayaran yang
            diperlukan untuk melakukan proses bantuan kami kepada Pengguna
            dengan cara yang paling tepat untuk tujuan yang jelas dalam memenuhi
            kebutuhan Pengguna sehubungan dengan penyediaan layanan yang Kami
            sediakan, sesuai dengan hukum dan undang-undang yang berlaku.
          </p>
          <h2 id="cookie">I. Cookie</h2>
          <p>
            Kami menggunakan penggunaan cookie. Dengan mengakses Rupiah.trade,
            Anda setuju untuk menggunakan cookie sesuai dengan Kebijakan Privasi
            Rupiah.trade.
          </p>
          <p>
            Sebagian besar situs web interaktif menggunakan cookie untuk
            memungkinkan kami mengambil detail pengguna untuk setiap kunjungan.
            Cookie digunakan oleh situs web kami untuk mengaktifkan
            fungsionalitas area tertentu agar lebih mudah bagi orang-orang yang
            mengunjungi situs web kami. Beberapa mitra afiliasi / iklan kami
            juga dapat menggunakan cookie.
          </p>
          <h2 id="transaksi-pembelian">II. Transaksi dan Pembelian</h2>
          <p>
            Dengan melakukan trankasi maka kami menganggap Anda sebagai orang
            yang cakap dan mampu untuk mengikatkan dirinya dalam sebuah
            perjanjian yang sah menurut hukum serta setuju dengan ketentuan
            transaksi yang telah ditetapkan oleh Rupiah.trade
          </p>
          <ol>
            <li>
              Pembeli wajib bertransaksi menggunakan prosedur transaksi yang
              telah ditetapkan oleh Rupiah.trade.
            </li>
            <li>
              Biaya transkasi melalui PayPal sepenuhnya dibebankan kepada
              pembeli berdasarkan <a href="#biaya">biaya layanan kami</a>.
            </li>
            <li>
              Pembeli disarankan untuk memeriksa kembali jumlah nominal
              pembayaran dengan jumlah tagihan yang tertera pada halaman
              pembayaran.
            </li>
            <li>
              Apabila terjadi proses pengembalian dana, maka pengembalian akan
              dilakukan melalui akun milik Pengguna yang akan bertambah sesuai
              dengan jumlah pengembalian dana, dan akan diteruskan ke rekening
              utama pengguna maksimal 7 hari kerja.
            </li>
          </ol>

          <h2 id="biaya">III. Biaya Layanan</h2>
          <p>
            Rupiah.trade merupakan situs web penjualan produk digital yang
            dikelolah secara profesional, sehingga kami memungut biaya layanan
            agar situs web ini tetap beroperasi.
          </p>
          <p>
            Berikut ini informasi biaya-biaya layanan yang dibebankan kepada
            pengguna Rupiah.trade.
          </p>
          <ol>
            <li>Biaya pendaftaran akun adalah GRATIS.</li>
            <li>
              Biaya layanan akan dibebankan kepada pembeli pada setiap transaksi
              sebesar
              <ul>
                <li>12% untuk transaksi antara 1 - 9 USD;</li>
                <li>10% untuk transaksi antara 10 - 49 USD;</li>
                <li>5% untuk transaksi diatas 49 USD;</li>
              </ul>
              biaya tersebut TIDAK TERMASUK biaya layanan pihak ketiga.
            </li>
            <li>
              Biaya layanan yang disediakan oleh pihak ketiga berikut ini:
              <ul>
                <li>
                  <strong>PayPal</strong> -{" "}
                  <a href="https://www.paypal.com/id/webapps/mpp/paypal-fees">
                    https://www.paypal.com/id/webapps/mpp/paypal-fees
                  </a>
                </li>
              </ul>
              akan dibebankan kepada pembeli pada setiap transaksi berdasarkan
              ketentuan yang ditetapkan oleh masing-masing pihak pengelola.
            </li>
            <li>
              Biaya transfer ke rekening bank dan e-wallet yang kami sediakan
              adalah GRATIS.
            </li>
          </ol>
          <p>
            Biaya yang tertera diatas berlaku sejak diterbitkannya halaman ini.
            Biaya sewaktu-waktu dapat berubah atas keputusan sepihak dari
            Rupiah.trade tanpa pemberitahuan sebelumnya.
          </p>

          <h2 id="batasan-pengguna">IV. Batasan Pengguna</h2>
          <p>
            Anda dapat mengakses situs web ini dari https://rupiah.trade untuk
            penggunaan pribadi dan tunduk pada batasan yang ditetapkan dalam
            syarat dan ketentuan ini.
          </p>
          <p>
            Anda <strong>TIDAK BOLEH</strong>:
          </p>
          <ol>
            <li>
              Menggunakan layanan kami untuk melakukan pembelian barang/jasa
              yang melanggar undang-undang; atau
            </li>
            <li>
              Menggunakan layanan kami untuk menyalurkan dana yang mendukung
              tindak kejahatan; atau
            </li>
            <li>
              Menggunakan layanan kami untuk mempromosikan bisnis atau
              kepentingan komersial atau kegiatan yang melanggar hukum.
            </li>
          </ol>

          <h2 id="pengembalian-dana">
           V. Pengembalian Dana <i>(Refund)</i>
          </h2>
          <p>
            Kami berkomitmen untuk memberikan pelayanan terbaik kepada Anda,
            termasuk hak Anda memiliki hak untuk meminta pengembalian dana
            sesuai dengan ketentuan pengembalian dana kami berikut ini:
          </p>
          <ol>
            <li>
              <p>
                Pengembalian dana dapat dilakukan apabila anda memenuhi syarat untuk pengembalian dana.
                Anda dinyatakan <strong>MEMENUHI</strong> syarat untuk
                pengembalian dana apabila:
              </p>
              <ul>
                <li>
                  Pesanan tidak Anda terima dalam 3 × 24 jam atau terjadi
                  kesalahan pada sistem kami
                </li>
                <li>
                  Produk/layanan yang kami kirimkan tidak sesuai dengan
                  informasi pesanan Anda.
                </li>
              </ul>
              <p>
                Anda dinyatakan <strong>TIDAK MEMENUHI</strong> syarat
                pengembalian dana apabila:
              </p>
              <ul>
                <li>
                  Dana yang salah kirim akibat kelalaian Anda, seperti kesalahan
                  pengetikan nomor rekening, nomor handphone atau rincian
                  pembayaran lainnya; atau
                </li>
                <li>
                  Anda melanggar salah satu atau lebih ketentuan yang telah kami ditetapkan; atau
                </li>
                <li>
                  Transkasi dilakukan melalui pihak tidak bertanggung jawab yang
                  mengatas namakan Rupiah.trade.
                </li>
              </ul>
            </li>
            <li>Khusus transaksi otomatis, dana akan dikembalikan dalam bentuk saldo akun dan dicairkan secara otomatis ke rekening utama maksimal 3 hari kerja.</li>
            <li>Dana yang kami kembalikan sesuai dengan jumlah yang kami terima dan tidak termasuk biaya layanan pihak ketiga.</li>
          </ol>

          <h2 id="persetujuan">VI. Persetujuan</h2>
          <p>
            Dengan menggunakan situs web kami, berarti{" "}
            <Link to="/privacy-policy">
              <strong>Menyetujui Kebijakan Privasi</strong>
            </Link>{" "}
            kami dan{" "}
            <strong>
              Menyetujui Syarat dan Ketentuann penggunaan layanan kami
            </strong>
            . Syarat dan ketentuan ini berlaku sejak tanggal diterbitkannya
            syarat dan ketentuan ini. Selama situs web dan informasi serta
            layanan di situs web disediakan secara gratis, kami tidak akan
            bertanggung jawab atas kehilangan atau kerusakan apa pun.
          </p>

          <p>Diperbarui pada: 06/05/2022</p>
        </div>
      </div>
      <Footer />
    </>
  );
}
